// 基础样式设置


/* 禁用字号自动调整 */
html {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  height: 100%;
  width: 100%;
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
  }
  &:hover{
    &::-webkit-scrollbar-thumb {
      background-color: rgba(196, 198, 204, 1);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(196, 198, 204, 1);
    }
  }
}
html * {
  outline:none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
}

/* 禁止缩放表单 */

input[type="submit"],
input[type="reset"],
input[type="button"],
input {
  resize: none;
  border: none;
}

/* 取消链接高亮  */

body,
div,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
p,
dl,
dt,
dd,
a,
img,
button,
form,
table,
th,
tr,
td,
tbody,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* 设置HTML5元素为块 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

textarea {
  outline: none;
}

input,
select {
  -webkit-appearance: none;
  appearance: none;
}

html,
body {
  height: 100%;
  touch-action: manipulation;
}

#root,
.app {
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
}

::-webkit-input-placeholder {
  color: #e1e4eb;
}

a {
  text-decoration: none;
  color: #007aff;
}

textarea {
  -webkit-appearance: none;
}

#root .am-list-item .am-list-line {
  padding-right: 0;
  margin-right: 15px;
}
/* 去掉列表前的标识, li 会继承 */
ol, ul {
  list-style: none;
}
/* 内外边距通常让各个浏览器样式的表现位置不同 */
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, hr, button, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  margin: 0;
  padding: 0;
}

/* 清除浮动 */
.clearfix {
  clear: both;
  overflow:hidden;
  _zoom:1;
  zoom:1;
}

.scrollbar{
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background-color: transparent;
  }
  &:hover{
    &::-webkit-scrollbar-thumb {
      background-color: rgba(196, 198, 204, 1);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(196, 198, 204, 1);
    }
  }
}