@import '~@/styles/public.scss';
.homeBox{
  width: 100%;
  height: 100%;
  padding-top: 0px;
  .banner {
    width: 100%;
    height: 376px;
    .bannerBox {
      width: 100%;
      height: 100%;
      background-repeat:no-repeat;
      background-size: 100% 100%;
      padding: 142px 158px;

      .content {
        font-family: 'Alibaba PuHuiTi';
        font-style: normal;
        font-weight: 500;
        font-size: 44px;
        line-height: 60px;
        color: #FFFFFF;
        margin-bottom: 63px;
      }
    }
  }
  .cardBox {
    padding: 80px 0;
    margin: 0 auto;
    text-align: center;
    background: #F5F7FA;
    background-repeat:no-repeat;
    background-size: 100% 100%;
    .title {
      font-family: 'Alibaba PuHuiTi';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      color: #2A2B2E;
    }
    .titleSc {
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #666E7E;
      margin-top: 64px;
    }
    .timeLineBox {
      width: 1000px;
      border-top: 1px solid #CCD1D9;
      border-bottom: 1px solid #CCD1D9;
      position: relative;
      margin: 102px auto 0 auto;
      min-height: 200px;
      .boxTitle {
        position: absolute;
        display: flex;
        left: 0;
        top: -18px;
        background: #F5F7FA;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
        }
        .boxTitleContent {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 36px;
          color: #2A2B2E;
          margin-left: 8px;
          padding-right: 8px;
        }
      }
      .timeLine {
        .timeLineContent {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 32px;
          color: #565759;
          padding: 20px 40px;
          background: hsla(0, 0%, 100%, 1);
          span {
            font-weight: 600;
            color: hsla(216, 100%, 53%, 1);
          }
        }
        :global {
          .ant-timeline {
            transform: translate(-400px, 36px);
            .ant-timeline-item-last {
              .ant-timeline-item-head {
                display: none;
              }
            }
          }
          
          .ant-timeline-item-content {
            width: 860px;
            transform: translateY(20px);
          }
          .ant-timeline-item-head {
            width: 18px;
            height: 18px;
          }
          .ant-timeline-item-label {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 26px;
            color: #0F6FFF;
          }
          .ant-timeline-item-tail {
            transform: translateX(4px);
            border-inline-start: 2px solid hsla(222, 65%, 84%, 1);
            inset-block-start: 24px;
            height: calc(100% - 30px);
          }
        }
      }
      
    }
    .bottomLineBox {
      width: 955px;
      text-align: left;
      margin: 24px auto 0 auto;
      .bottomLine {
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 36px;
        color: #2A2B2E;
        vertical-align: top;
        position: relative;
        padding-left: 36px;
        span {
          width: 6px;
          height: 6px;
          background: #2A2B2E;
          display: inline-block;
          border-radius: 50%;
          position: absolute;
          left: 8px;
          top: 15px;
        }
      }
    }
    .challengeBox {
      display: flex;
      justify-content: center;
      margin-top: 64px;
      transition: all .6s ease;
      .challenge {
        margin-right: 32px;
        background: #F5F7FA;
        border-radius: 2px;
        width: 378px;
        height: 245px;
        padding: 41px 0 41px 0;
        margin-bottom: 32px;
        border-radius: 2px;
        transition: all .6s ease;
        img {
          width: 90px;
          height: 90px;
          background: #F5F7FA;
        }
        .animationIcon {
          // width: 100%;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 0 auto;
          transition: all .6s ease;
        }
        .challengeContent {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 36px;
          text-align: center;
          color: #2A2B2E;
          margin-top: 12px;
        }
        .challengeContent2 {
          display: none;
        }
      }
      .challenge:hover {
        cursor: pointer;
        position: relative;
        padding-top: 18px;
        background: linear-gradient(248.11deg, #3CA1FF 0%, #2970D5 100%);
        
        img {
          position: absolute;
          top: -45px;
          left: 50%;
          transform: translateX(-50%);
        }
        .animationIcon {
          display: none;
        }
        .challengeContent {
          color: #FFFFFF;
          margin-top: 0;
          font-weight: 600;
        }
        .challengeContent2 {
          display: inline-block;
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          color: #FFFFFF;
          margin: 6px 32px 0 32px;
          text-align: left;
        }
      }
    }
    .challengeBoxHover:hover {
      margin-top: 20px !important;
    }
    .solveBox {
      margin-top: 64px;
      display: flex;
      justify-content: center;
      .left {
        height: 451.2px;
        width: 800px;
        border-radius: 2px;
        margin-right: 20px;
        background: #FFFFFF;
      }
      .right {
        height: 451px;
        width: 382px;
        left: 820px;
        border-radius: 2px;
        padding: 20px;
        overflow-y: scroll;
        text-align: left;
        background: #FFFFFF;
        @extend .scrollbar;

        .line1 {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          color: #565759;
        }
        .line2 {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          color: #2A2B2E;
          margin-bottom: 8px;
        }
        .line3 {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          color: #565759;
          margin: 4px 0;
        }
        .lineBox {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 12px;
          .lineBoxItem {
            width: 142px;
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #CFD9E4;
            font-weight: 400;
            font-size: 14px;
            color: #565759;
            border-radius: 4px;
            margin: 0 8px 8px 0;
            text-align: center;
            line-height: 32px;
          }
        }
      }
    }
    .appScBox {
      :global {
        .ant-tabs {
          margin-top: 64px;
          .ant-tabs-nav-wrap {
            justify-content: center;
            box-shadow: 0px 30px 80px rgba(3, 12, 27, 0.05), 0px 12.5216px 10.0172px rgba(3, 12, 27, 0.035);
            .ant-tabs-tab {
              font-weight: 400;
              font-size: 20px;
              line-height: 27px;
              color: #0F6FFF;
              margin-right: 48px;
            }
          }
        }
        
      }
      .appSc {
        width: 800px;
        margin: 40px auto 0 auto;
        border: 1px solid #CCD1D9;
        img {
          width: 800px;
          height: 338px;
        }
        .appScTitle {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 36px;
          color: #2A2B2E;
          margin: 20px 32px;
          text-align: left;
        }
      }
    }
    .programBox {
      width: 950px;
      min-height: 450px;
      margin: 64px auto 0 auto;
      display: flex;
      flex-wrap: wrap;
      
      .programBoxItem {
        width: 50%;
        height: 225px;
        transition: all .6s ease;
        img {
          width: 88px;
          height: 88px;
          margin-top: 20px;
        }
        .programContent {
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 36px;
          text-align: center;
          color: #2A2B2E;
          width: 80%;
          margin: 22px auto 32px auto;
        }
        .contentHover {
          padding: 20px;
          display: none;
          font-family: 'Microsoft YaHei';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          color: #565759;
          text-align: left;
        }
        border: 1px solid #CCD1D9;
      }
      .programBoxItem:hover {
        background: #FFFFFF;
        min-height: 225px;
        img {
          display: none;
        }
        .programContent {
          font-weight: 600;
          margin: 22px auto 12px auto;
        }
        .contentHover {
          display: inline-block;
        }
      }
    }
    
  }
  .sucBox {
    width: 1230px;
    height: 242px;
    display: flex;
    flex-wrap: nowrap;
    margin: 64px auto 0 auto;
    justify-content: center;
    .sucItem {
      width: 378.6px;
      height: 242px;
      margin: 0 16.16px;
      border: 1px solid #B7BAC7;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      .sucContent {
        font-family: 'Microsoft YaHei';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(5px);
        width: 100%;
        text-align: left;
        padding: 7.6px 24.2px;
      }

      &:hover > .message {
        padding-top: 23px;
        background: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(5px);
        top: 0;
        opacity: 1;
      }
      .message {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 180px;
        padding: 16px 23px 0 23px;
        left: 0;
        text-align: left;
        transition-property: all;
        transition-duration: 1s, 0.3s;
        opacity: 0;
        .title {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .text {
          font-size: 14px;
          line-height: 28px;
          color: #ffffff;
          margin-bottom: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .link {
          display: inline-block;
          border: 1px solid #ffffff;
          border-radius: 32px;
          color: #ffffff;
          font-size: 14px;
          padding: 6px 20px;
          cursor: pointer;
          &:hover {
            color: #0f6fff;
            border-color: #0f6fff;
          }
        }
      }
    }
  }
}